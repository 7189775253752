/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-04
 * @Description:
 */
import { handleTrack, getVuexInstance } from '@/utils/index.js'
import {
  productTransformData,
  filterPropByProducts,
  parseFloatNum
} from '@/utils/business/product.js'

function sendSubmitCartTrack (products) {
  handleTrack('submit_by_shopping_cart', {
    cp_product_list: productTransformData(products)
  })
}

function useCartVuex () {
  const _store = getVuexInstance()
  const { getters, state } = _store
  return {
    _store,
    selectedCartList: getters['cart/selectedCartList'],
    cartList: state.cart.cartList,
    amountTotalInfo: getters['cart/amountTotalInfo'],
    defaultCountryInfo: state.payment.defaultCountryInfo
  }
}

// 购物车展示券后价格
function useCouponPrice () {
  // 获取优惠后总价
  function getCountCouponPrice (discountAmount) {
    const { amountTotalInfo } = useCartVuex()
    const { amount, currency } = amountTotalInfo
    if (!amount || !discountAmount) return ''

    return `${parseFloatNum(amount - discountAmount)} ${currency}`
  }

  // 更新每一项优惠价
  function updateItemCouponPrice (products) {
    const { _store, cartList } = useCartVuex()
    const newCartList = cartList.map((cartItem) => {
      // 找到券后商品信息
      const item = products.find(
        ({ product_id }) => product_id === cartItem.id
      )
      const { reduce_amount_display = 0 } = item || {}

      // 更新每个购买项的优惠信息
      cartItem.reduce_amount_display = reduce_amount_display
      return cartItem
    })
    // 更新vuex中数据
    _store.commit('cart/UPDATE_CART_STATE', {
      cartList: newCartList
    })
  }

  // 更新每一项的券后价格
  async function updateCartCouponPrice () {
    const { selectedCartList, defaultCountryInfo } = useCartVuex()
    const { country_en_us: country } = defaultCountryInfo
    const product_cart = filterPropByProducts(selectedCartList)

    const { coupons } = await Apis.getUseableCoupons({
      country,
      product_cart
    })
    const { total_reduce_amount_display, coupon_products = [] } =
      coupons[0] || {}

    updateItemCouponPrice(coupon_products)
    return {
      countCouponPrice: getCountCouponPrice(total_reduce_amount_display)
    }
  }

  return { updateCartCouponPrice }
}

export { sendSubmitCartTrack, useCouponPrice }
