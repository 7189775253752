<!--
 * @Date: 2022-06-14 13:53:46
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-10 16:36:25
 * @FilePath: /dc-pay-front/src/views/Cart/index.vue
-->
<template>
  <div style="overflow: hidden">
    <!-- 自定义导航栏 -->
    <NavBar
      title=""
      :left-arrow="!hideNavBarLeft"
      :left-text="hideNavBarLeft ? '' : $route.meta.title"
    >
      <template v-if="cartList.length" #right>
        <div v-show="isBuyModel">
          <span
            v-show="selectedCartList.length"
            class="common-nav-bar-btn"
            @click="clickFavoriteBtn"
          >
            {{ $t("fav.favText") }}
          </span>
          <span class="common-nav-bar-btn" @click="clickManageBtn">
            {{ $t("fav.manageBtn") }}
          </span>
        </div>
        <span
          v-show="isManageModel"
          class="common-nav-bar-btn"
          @click="clickManageSucBtn"
        >
          {{ $t("fav.sucText") }}
        </span>
      </template>
    </NavBar>
    <!-- 加载中 -->
    <van-loading
      style="
        text-align: center;
        padding-top: 1rem;
        background-color: transparent;
      "
      type="spinner"
      v-if="loading.list"
    />
    <!-- 购物车列表 -->
    <div v-else-if="!isEmpty" style="overflow: hidden;">
      <!-- 可购买商品 -->
      <ul class="carts-container">
        <ProductItem
          class="carts-item"
          v-for="productItem in cartList"
          :key="productItem.id"
          :showCouponPrice="productItem.selected"
          :productItemInfo="productItem"
          selectedStyle="multipleChoice"
          :stepperPropsHelp="{theme: '', 'disable-input': false}"
          v-bind="productItemProp"
          @clickProductItem="clickProductItem"
        ></ProductItem>
      </ul>

      <div v-if="isBuyModel && disabledCartList.length" class="carts-container">
        <!-- 失效商品 -->
        <p class="flex-between list-describe">
          <span>
            <!-- 失效商品 {{ disabledCartList.length }} 件 -->
            {{ $t("cart.overdueGoodNum", { num: disabledCartList.length }) }}
          </span>
          <span class="btn-color" @click="cleanDisabledProducts">
            <!-- 清空失效商品 -->
            {{ $t("cart.emptyGoods") }}
          </span>
        </p>
        <ul>
          <!-- 失效商品列表 -->
          <ProductItem
            class="carts-item disabled"
            v-for="productItem in disabledCartList"
            :key="productItem.id"
            :productItemInfo="productItem"
            selectedStyle="multipleChoice"
            operatorPosition="none"
            :showNumText="false"
          ></ProductItem>
        </ul>
      </div>
    </div>

    <!-- 列表为空展示 -->
    <van-empty
      v-else
      class="custom-image"
      :image="require('@/assets/empty/cart_empty.png')"
      description=""
    />

    <!-- 待支付订单弹窗 -->
    <UnpaidPopup
      ref="unpaidPopupRef"
      :orderInfo="unpaidOrderInfoForPopup"
      @cancel="clickSubmit"
      @confirm="confirmToPayLastOrder"
    ></UnpaidPopup>

    <!-- 操作底栏 -->
    <FooterOperatorBar
      :loading="loading.submitBtn"
      :footerBarType="FOOTER_BAR.cartOperator"
      :businessInfo="footerBusinessInfo"
      @submit="clickSubmit"
    >
      <!-- 自定义管理模式底栏(v-if为false时，自动展示buy model底栏) -->
      <template #customCartOperator v-if="isManageModel">
        <div class="flex-between flex-vcenter flex-auto">
          <van-checkbox v-model="selectAll" @click="selectAllBox(selectAll)">
            {{ $t("fav.selectAll") }}
          </van-checkbox>
          <Button
            :disabled="!selectedCartList.length"
            :loading="loading.delete"
            type="danger"
            style="min-width: 2.2rem;width: auto;"
            @click="confirmBeforeDelete"
          >
            {{ $t("fav.del") }}
          </Button>
        </div>
      </template>
    </FooterOperatorBar>

    <!-- 创建收藏夹弹窗 -->
    <EditPopup
      ref="createFavPopRef"
      :editType="EDIT_TYPE_ENUM.create"
      :products="selectedCartList"
      @createSuc="createFavoriteSuc"
    />

    <!-- 提示收藏弹窗 -->
    <Dialog
      v-model="showFavTipDialog"
      title=""
      show-cancel-button
      :confirm-button-text="$t('fav.add')"
      :cancel-button-text="$t('fav.notAdd')"
      @confirm="checkBeforeShowCreateFav"
      @cancel="cancelFavoriteSuc"
      @close="closeFavTipPop"
    >
      <div class="dialog-cont">
        <p>{{ $t("fav.confirmAddTip") }}</p>
        <van-checkbox class="check-box" v-model="notInformFlag">
          {{ $t("fav.notTipToday") }}
        </van-checkbox>
      </div>
    </Dialog>
  </div>
</template>

<script>
import EditPopup, {
  EDIT_TYPE_ENUM
} from '@/views/Favorite/popup/EditPopup.vue'
import UnpaidPopup from '@/views/Payment/popup/UnpaidPopup.vue'
import { Empty, Checkbox } from 'vant'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import FooterOperatorBar, {
  FOOTER_BAR
} from '@/components/FooterOperatorBar.vue'
import ProductItem from '@/views/Home/ProductItem/ProductItem.vue'
import {
  filterPropByProducts,
  addCountdownForProducts,
  sendFavAndRecommandTrack
} from '@/utils/business/product.js'
import { PAYMENT_PAGE_MAP } from '@/store/modules/payment.js'
import NavBar from '@/components/NavBar.vue'
import { LocalOperator } from '@/utils/business.js'
import { favoriteConfig } from '@/utils/globalData.js'
import { handleTrack } from '@/utils/index.js'
import { sendSubmitCartTrack, useCouponPrice } from './index.js'
import Button from '@/components/ui/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'

const { updateCartCouponPrice } = useCouponPrice()
const CART_MODEL = {
  // 支付模式
  buy: Symbol('buy'),
  // 管理模式
  manage: Symbol('manage')
}
export default {
  components: {
    Button,
    [Checkbox.name]: Checkbox,
    Dialog,
    NavBar,
    EditPopup,
    UnpaidPopup,
    [Empty.name]: Empty,
    FooterOperatorBar,
    ProductItem
  },
  data () {
    return {
      footerBusinessInfo: {},
      // 今日不再提醒 checkbox
      notInformFlag: false,
      // 展示提示收藏弹窗
      showFavTipDialog: false,
      selectAll: false,
      currModel: CART_MODEL.buy,
      loading: {
        delete: false,
        list: false,
        submitBtn: false
      }
    }
  },
  computed: {
    ...mapState('cart', ['cartList', 'cartLength', 'disabledCartList']),
    ...mapGetters('cart', ['selectedCartList', 'amountTotalInfo']),
    ...mapGetters('payment', ['unpaidOrderInfoForPopup']),

    hideNavBarLeft () {
      return this.isManageModel
    },
    productItemProp () {
      // 购物车页切为操作模式
      if (this.isManageModel) {
        return {
          operatorPosition: 'none',
          showNumText: true
        }
      }
      return {
        operatorPosition: 'foot',
        showNumText: false
      }
    },
    isSelectAll () {
      return this.selectedCartList.length === this.cartList.length
    },
    isBuyModel () {
      return CART_MODEL.buy === this.currModel
    },
    isManageModel () {
      return CART_MODEL.manage === this.currModel
    },
    // filter [{count,product_id}]
    filterSelectedList () {
      return filterPropByProducts(this.selectedCartList)
    },
    // 购物车是否为空
    isEmpty () {
      return this.cartList.length + this.disabledCartList.length <= 0
    }
  },
  methods: {
    ...mapMutations('cart', ['SET_CART_LIST_SELECTED']),

    ...mapActions('tool', ['showLoading', 'closeLoading', 'showDialog']),
    ...mapActions('payment', ['goPaymentPage']),
    ...mapActions('product', ['validateProductAndTip']),
    ...mapActions('cart', ['getCartList', 'updateCartCount', 'getCartCount']),
    ...mapActions('favorite', ['getFavoriteList']),

    /**
     * @description: 选中或取消 所有商品的勾选
     * @param {boolean} isSelected
     */
    selectAllBox (isSelected) {
      this.SET_CART_LIST_SELECTED(isSelected)
    },
    confirmBeforeDelete () {
      this.showDialog({
        title: '',
        desc: this.$t('fav.confirmDelTip'),
        showCancelButton: true,
        confirmButtonText: this.$t('fav.del'),
        tipConfirmCallbak: this.clickDeleteBtn
      })
    },
    async clickDeleteBtn () {
      const product_cart = [...this.filterSelectedList]
      product_cart.forEach((item) => (item.count = 0))
      this.loading.delete = true
      await this.updateCartCount({ product_cart }).finally(
        () => (this.loading.delete = false)
      )
      if (this.selectAll) {
        this.clickManageSucBtn()
      }
    },
    async clickFavoriteBtn () {
      const isPass = await this.validateShowCreateFav()
      if (!isPass) return
      this.showDialog({
        title: this.$t('fav.confirmAddSelectFavTip'),
        desc: '',
        showCancelButton: true,
        confirmButtonText: this.$t('fav.add'),
        tipConfirmCallbak: () => {
          this.sendFavTrack('add_fav_by_btn')
          this.showCreateFavPop()
        }
      })
    },
    // 校验是否显示【创建收藏夹弹窗】
    async checkBeforeShowCreateFav () {
      this.sendFavTrack('add_fav_by_submit_cart')
      const isPass = await this.validateShowCreateFav()
      if (!isPass) return
      this.showCreateFavPop()
    },
    sendFavTrack (eventName) {
      const { selectedCartList: products } = this
      sendFavAndRecommandTrack({ eventName, products })
    },
    /**
     * @description: 判断是否显示【创建收藏夹弹窗】
     * @return {Promise:boolean} true 显示；其他不显示
     */
    async validateShowCreateFav () {
      const { groups = [] } = await this.getFavoriteList()
      const len = groups.length
      const { maxLength } = favoriteConfig
      let validatePass = true

      if (len >= maxLength) {
        this.$toast(this.$t('fav.needCleanFavTip', { maxLength }))
        validatePass = false
      }
      return validatePass
    },
    showCreateFavPop () {
      this.$refs.createFavPopRef.showPopup()
    },
    clickManageBtn () {
      this.selectAll = false
      this.currModel = CART_MODEL.manage
      this.SET_CART_LIST_SELECTED(false)
    },
    clickManageSucBtn () {
      this.currModel = CART_MODEL.buy
      this.SET_CART_LIST_SELECTED(true)
    },
    closeFavTipPop () {
      if (this.notInformFlag) {
        handleTrack('check_not_tip_today')
        _localStorage.set('checkNotInformFavoriteTimestamp', +new Date())
      }
    },
    cancelFavoriteSuc () {
      this.createFavCallback()
    },
    setHighlightFav () {
      const { keys, set } = LocalOperator
      set(keys.highlightFavEntry, true)
    },
    createFavoriteSuc () {
      this.setHighlightFav()
      this.createFavCallback()
    },
    clickProductItem (item) {},
    // 注释【提示用户收藏商品逻辑】
    // beforeSubmit () {
    //   // 是否显示收藏提示窗
    //   let showFavPop = true
    //   // 勾选【今日不再提醒】的时间
    //   const checkTimestamp = _localStorage.get(
    //     'checkNotInformFavoriteTimestamp'
    //   )
    //   // 在24H内触发过【今日不再提醒】就不需弹窗
    //   if (checkTimestamp) {
    //     const currTimestamp = +new Date()
    //     const aDayTimestamp = 3600 * 24 * 1000
    //     if (currTimestamp - checkTimestamp < aDayTimestamp) {
    //       showFavPop = false
    //     }
    //   }
    //   if (showFavPop) {
    //     this.showTipFavPop(this.clickSubmit)
    //   } else {
    //     this.clickSubmit()
    //   }
    // },
    // 控制【是否将此次购买组合添加至收藏夹】弹窗显示
    showTipFavPop (callback = () => {}) {
      this.createFavCallback = callback
      this.showFavTipDialog = true
    },
    async clickSubmit () {
      const { filterSelectedList: product_cart, validateProductAndTip } = this
      this.loading.submitBtn = true
      const { is_done } = await this.$store.dispatch(
        'payment/searchLastOrderState'
      )
      if (!is_done) {
        this.$refs.unpaidPopupRef.showPopup(true)
        this.loading.submitBtn = false
        return
      }

      const list = await this.$store.dispatch('product/getProductByIds', {
        product_cart
      })
      const { existUnpayable } = await validateProductAndTip({
        list,
        tipConfirmCallbak: this.disposeLimitProduct
      })
      if (!existUnpayable) {
        sendSubmitCartTrack(this.selectedCartList)
        // 校验通过，正常下单
        await this.submitCart()
        this.loading.submitBtn = false
        this.goPaymentPage()
      }
      this.loading.submitBtn = false
    },
    // 点击待支付弹窗的确认支付按钮
    confirmToPayLastOrder () {
      this.goPaymentPage({ pageType: PAYMENT_PAGE_MAP.uppaid })
    },
    async disposeLimitProduct ({ excessInfoList }) {
      if (excessInfoList?.length) {
        const product_cart = excessInfoList.map((item) => ({
          count: item.payableNum,
          product_id: item.product_id
        }))
        // 更新限购数量到最大
        await this.updateCartCount({ product_cart })
      }
      // 更新购物车列表，令限时商品进入失效队列
      this.getCartList()
    },
    submitCart () {
      const product_cart = this.filterSelectedList
      return this.$store.dispatch('cart/submitCart', { product_cart })
    },
    async cleanDisabledProducts () {
      const product_cart = this.disabledCartList.map((item) => ({
        count: 0,
        product_id: item.id
      }))

      await this.updateCartCount({ product_cart })
      await this.getCartList()
    },
    async initPage () {
      // 操作收藏夹提示弹窗后的回调
      this.createFavCallback = () => {}
      this.loading.list = true
      await this.getCartList()
      // 更新券后价格
      await this.getCartCount()
      this.loading.list = false
      addCountdownForProducts(this.cartList)
    }
  },
  mounted () {},
  created () {
    this.EDIT_TYPE_ENUM = EDIT_TYPE_ENUM
    this.FOOTER_BAR = FOOTER_BAR
    this.footerBusinessInfo = {
      discountCountPrice: '',
      strutDom: document.body
    }
    this.initPage()
  },
  watch: {
    'amountTotalInfo.count': {
      immediate: true,
      async handler (count) {
        if (count > 0 && this.selectedCartList.length > 0) {
          const { countCouponPrice } = await updateCartCouponPrice()
          this.footerBusinessInfo.discountCountPrice = countCouponPrice
        } else {
          this.footerBusinessInfo.discountCountPrice = ''
        }
      }
    },
    isSelectAll (selectAllFlag) {
      this.selectAll = selectAllFlag
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .stepper-root {
  .van-stepper__input {
    color: var(--dp-text-tertiary);
  }
}

/deep/.custom-image {
  margin-top: 300px;
  .van-empty__image {
    width: 400px;
    height: 310px;
  }
}
.list-describe {
  padding: var(--dp-sp-2xl);
  border-bottom: 1px solid var(--dp-divider-primary);
  .btn-color {
    color: var(--dp-warning-primary);
    cursor: pointer;
  }
}
.carts-container {
  margin: var(--dp-sp-md);
  background-color: var(--dp-bg-secondary);
  border-radius: var(--dp-b-rd-md);
  .carts-item {
    padding: 35px 30px 24px 20px;
  }
}
.dialog-cont {
  // font-size: 30px;
  // padding: 100px 80px 80px;
  text-align: center;
  .check-box {
    padding-top: 32px;
    justify-content: center;
    /deep/.van-checkbox__icon {
      font-size: 28px;
    }
    /deep/.van-checkbox__label {
      font-size: var(--dp-text-md);
      color: var(--dp-text-tertiary);
    }
  }
}
</style>
