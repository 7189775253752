<!--
 * @Date: 2022-08-16 11:07:04
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-08-31 11:50:36
 * @FilePath: /dc-pay-front/src/views/Favorite/popup/EditPopup.vue
-->
<template>
  <!-- @click.stop 避免点击弹窗后，点击事件冒泡到父组件导致选中购买项 -->
  <div @click.stop="">
    <van-popup
      class="common-popup"
      v-model="show"
      :closeable="false"
      @click-close-icon="clickCancel"
      :close-on-click-overlay="operatorMap.closeable"
    >
      <div class="common-popup-main">
        <van-field
          v-model="group_name"
          clearable
          :maxlength="50"
          :placeholder="$t('fav.writeNameTip')"
          :formatter="formatterGroupName"
          format-trigger="onBlur"
        />
      </div>

      <div class="common-btn-group">
        <Button
          v-if="operatorMap.closeable"
          type="cancel"
          class="common-btn-group__item"
          @click="clickCancel"
        >
          {{ $t("common.cancel") }}
        </Button>
        <Button
          type="info"
          class="common-btn-group__item"
          @click="operatorMap.submit"
        >
          {{ $t("common.confirm") }}
        </Button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/ui/Button.vue'

export const EDIT_TYPE_ENUM = {
  edit: Symbol('edit'),
  create: Symbol('create')
}
export default {
  components: { Button },
  data () {
    return {
      group_id: '',
      group_name: '',
      show: false
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),

    operatorMap () {
      const { editInitForm, createInitForm, executeEdit, executeCreate } = this
      const map = {
        [EDIT_TYPE_ENUM.edit]: {
          initForm: editInitForm,
          submit: executeEdit,
          closeable: true
        },
        [EDIT_TYPE_ENUM.create]: {
          initForm: createInitForm,
          submit: executeCreate,
          closeable: false
        }
      }
      return map[this.editType] || {}
    }
  },
  props: {
    editType: {
      type: Symbol,
      default: EDIT_TYPE_ENUM.create
    },
    // 收藏夹信息 { id, name, ...more }, 仅编辑时传
    favoriteInfo: {
      type: Object,
      default: () => ({})
    },
    // 购买项信息 [{product_id, count}], 仅创建时传
    products: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatterGroupName (name) {
      return name.trim()
    },
    clickCancel () {
      this.showPopup(false)
      this.$emit('cancel')
    },
    async executeCreate () {
      if (!this.validateForm()) return
      await this.createApi().catch(err => {
        const { code } = err || {}
        // 222 表示已超过可拥有的收藏夹个数
        if (code === 222) {
          this.showPopup(false)
        }
        return Promise.reject(err)
      })
      this.$toast(this.$t('fav.addFavTip'))
      this.$emit('createSuc')
      this.showPopup(false)
    },
    async executeEdit () {
      if (!this.validateForm()) return
      await this.editApi()
      this.$toast(this.$t('fav.editFavTip'))
      this.$emit('updateSuc')
      this.showPopup(false)
    },
    validateForm () {
      let isPass = true
      let msg = ''

      if (!this.group_name) {
        isPass = false
        msg = this.$t('fav.writeNameTip')
      }
      msg && this.$toast(msg)
      return isPass
    },
    createApi () {
      const { role_id } = this.userInfo
      let { group_name, products } = this

      products = products.map(
        ({
          id,
          name,
          image_url,
          amount,
          amount_display,
          currency,
          extra_benefit,
          content,
          count
        }) => ({
          id,
          name,
          image_url,
          amount,
          amount_display,
          currency,
          extra_benefit,
          content,
          count
        })
      )

      return Apis.addFavorite({ group_name, role_id, products })
    },
    editApi () {
      const { role_id } = this.userInfo
      const { group_name, group_id } = this

      return Apis.updateFavorite({ group_name, group_id, role_id })
    },
    editInitForm () {
      // // favoriteInfo 在computed更新，需延迟后获取
      const { id, name } = this.favoriteInfo
      this.group_id = id
      this.group_name = name
    },
    createInitForm () {
      this.group_id = ''
      this.group_name = ''
    },
    showPopup (isShow = true) {
      this.$nextTick(() => {
        this.operatorMap.initForm()
        this.show = isShow
      })
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
@import url("~@/styles/common.less");
</style>
